import React from 'react'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import Paragraph from '../components/paragraph'
import Team from '../components/team'
import PageWrap from '../components/pageWrap'
import {StaticQuery, graphql} from 'gatsby'

const AboutUsPage = () => (
  <StaticQuery
    query={graphql`
      query {
        AboutPageImage: file(relativePath: { eq: "cityscape-three.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2880, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <PageHeader image={data.AboutPageImage.childImageSharp}>
          About Us
        </PageHeader>
        <PageWrap>
          <Paragraph>
            SCIT Labs, Inc was incorporated in 2007. SCIT Labs products are
            designed to increase system availability, reduce operations cost and
            enhance system security. The underlying research and development
            effort has resulted in six patents and products that increases
            server resilience while ensuring continuity of operations. SCIT Labs
            has exclusive license to SCIT technology. Our initial effort was in
            defining and building servers based on SCIT technology. We are
            extending the range of SCIT products to include military and
            commercial applications.
          </Paragraph>
        </PageWrap>
        <Team />
      </Layout>
    )}
  />
)

export default AboutUsPage
